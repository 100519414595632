import React from "react"
import PageLayout from "../components/PageLayout"
import ContentLayout from "../components/ContentLayout"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { colors } from "../utils/theme.js"

export default () => (
  <PageLayout>
    <ContentLayout>
      <h1
        css={css`
          font-weight: normal;
        `}
      >
        Features
      </h1>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            position: sticky;
            margin-top: 60px;
            top: 180px;
            width: 20%;
          `}
        >
          <Link
            to="features#home"
            css={css`
              text-decoration: none;
              color: ${colors.primary.darker};
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Company Home
          </Link>
          <Link
            to="features#extension"
            css={css`
              text-decoration: none;
              color: ${colors.primary.darker};
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Browser extension
          </Link>
          <Link
            to="features#announcements"
            css={css`
              text-decoration: none;
              color: ${colors.primary.darker};
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Announcements
          </Link>
          <Link
            to="features#messages"
            css={css`
              text-decoration: none;
              color: ${colors.primary.darker};
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Team messages
          </Link>
          <Link
            to="features#people"
            css={css`
              text-decoration: none;
              color: ${colors.primary.darker};
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            People
          </Link>
        </div>
        <div
          css={css`
            width: 80%;
          `}
        >
          <h2 id="home">Company Home</h2>
          <p>Today, we have many tools to communicate.</p>
          <p>
            From the emails, Slack, whatsapp, documentations tools, or even
            weekly recaps, town halls... There is a huge variety of services to
            be checked or meetings to attend every day, with a big impact on our
            productivity.
          </p>
          <p>
            When the company needs to communicate, it is hard task to find the
            good channel.
          </p>
          <p>
            Zenshare aims to make it easy: share the information you want at any
            time, make sure it is read by your employees in the best delays
            without disturbing them and cut their productivity thanks to a
            smooth integration in their workflow.
          </p>

          <h2 id="extension">Browser extension</h2>
          <p></p>
          <p>Our goal is to be well integrated in your employees workflow.</p>
          <p>
            The most used tool by everybody in a company is the browser, so we
            think that having access to the information while browsing the web
            is the easiest way to be updated.
          </p>
          <p>
            We have built a browser extension for that matter, which shows the
            updates when opening a new tab.
          </p>
          <p>
            In a matter not to disturb the employees with a flow of information
            every time they open a tab, by default the extension shows only a
            beautiful landscape with the current date and a kind message.
          </p>
          <p>
            As soon as there is an update, they see a notification encouraging
            them to read it, the homepage appears directly in the new tab.
          </p>

          <h2 id="announcements">Announcements</h2>
          <h2 id="messages">Team messages</h2>
          <p>
            The company home is a good place to showcase messages about team
            events, company life...
          </p>

          <p>
            Every user can compose a message, and define an expiration date. The
            messages are then shown on the company homepage from the soonest
            expiring to the later so that the information is priorized for your
            team by the information expiracy !
          </p>

          <h2 id="people">People</h2>
          <p></p>
        </div>
      </div>
    </ContentLayout>
  </PageLayout>
)
